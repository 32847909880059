class OrderService {
  constructor() {

  }


     fetchMyOrders() {
      
        return pendingOrder.orderByChild("user_id").equalTo(user.uid).once('value').then(function(snapshot) {
      return snapshot.val();
  }, function(error) {
  // The Promise was rejected.
  console.error(error);
});

  }
  
  
  


}